<template>
  <div>
    <div class="bg-white rounded-lg shadow-md py-10 px-5">
      <div v-if="!is_phone_updated">
        <div class="flex flex-wrap w-full">
          <vs-input  v-model="userInfo.full_name" :label="$t('full_name')" class="w-full lg:w-1/2 mt-5 px-2"/>
          <vs-input  v-model="userInfo.email" :label="$t('email')" class="w-full lg:w-1/2 mt-5 px-2" dir="ltr"/>
          <vs-input  v-model="userInfo.phone_number" :label="$t('phone_number')" class="w-full lg:w-1/2 mt-5 px-2" dir="ltr"/>
        </div>
        <div class="flex justify-end mt-10 px-2">
          <vs-button @click="update" :disabled="is_sending_data">{{$t('updateInfo')}}</vs-button>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full justify-center">
          <vs-input  v-model="vc" :label="$t('vc_enterance')" class="w-full sm:w-1/2 mt-5"/>
        </div>
        <div class="flex justify-center mt-5">
          <span class="text-3xl">{{countdown}}</span>
        </div>
        <div class="flex justify-center mt-5">
          <vs-button @click="verifyVc" class="w-1/3" :disabled="countdown == 0 || is_sending_data">{{$t('verify')}}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
export default {
  data() {
    return {
      userInfo: {},
      is_phone_updated: false,
      vc: null,
      countdown: 60,
      is_sending_data: false
    };
  },
  computed:{
    user(){
      return typeof(this.$store.state.auth.user) == 'string' ? JSON.parse(this.$store.state.auth.user) : this.$store.state.auth.user;
    },
  },
  created() {
    this.$store.dispatch('loader/loaderOff')
    this.userInfo= JSON.parse(JSON.stringify(this.user))
  },
  methods: {
    update() {
      this.is_sending_data = true;
      this.$store.dispatch('auth/updateUserInformation', this.userInfo)
      .then(response => {
        if (response.data.is_phone_updated) {
          this.is_phone_updated = true;
          this.vc = response.data.vc ? response.data.vc : null
          this.countdown = response.data.opt_seconds
          this.countdown = this.countdown - 1;
          this.is_sending_data = false;
        }
        this.$vs.notify({
          color: 'success',
          text: response.data.success_message
        });
      })
      .catch(error => {
        this.$vs.notify({
          color: 'danger',
          type: 'error',
          title: 'حدث خطأ ما!',
          text: 'تأكد انك قمت بتعبئة جميع الحقول الاجبارية'
        });
      });
    },
    verifyVc() {
      this.is_sending_data = true;
      this.$store.dispatch('auth/verifyVc', { vc: this.vc, phone_number: this.userInfo.phone_number })
      .then(response => {
        this.is_sending_data = false;
        this.$vs.notify({
          color: 'success',
          type: '',
          title: '   ',
          text: response.data.success_message,
        });
        this.is_phone_updated = false; 
        
      })
      .catch(error => {
        alert('failed notification triggered');
      });
    },
  },
  watch: {
    countdown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
              this.countdown--;
          }, 1000);
        }
      },
    }
  }
};

</script>